/* navbar.css */
body{
  font-family: 'Times New Roman', Times, serif;
}
.navbar {
  background-color: rgba(190, 190, 199, 0.705);
  padding: 2px 0;
  box-shadow: 0 4px 2px -2px gray;
  width: 100%;
  z-index: 1000;
  position: sticky;
  height: 57px;
  border-radius: 7px; /* Agrega bordes redondeados */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-image {
  max-width: 80px;
  height: 40px;
  margin-left: 30px;
}

.logo-image2 {
  max-width: 90px;
  height: 50px;
  margin-left: 15px;
}

.logo-image3 {
  max-width: 70px;
  height: 70px;
  border-radius: 50px;
}

.navbar-custom a {
  color: rgb(21, 6, 136);
  margin-right: 50px;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  
}

.navbar-links a:hover {
  color: rgb(96, 114, 255)
}

.navbar-button-container {
  display: flex;
  align-items: center;
}


/* Ajusta según la altura de tu barra de navegación */
.element-despues-del-navbar {
  margin-top: 60px;
}

@media (max-width: 428px){
  
  .logo-image {
    max-width: 80px;
    height: 40px;
    margin-left: 1px;
  }
  
  .logo-image2 {
    max-width: 80px;
    height: 40px;
    margin-left: 2px;
  }
  
  .logo-image3 {
    max-width: 80px;
    height: 50px;
    border-radius: 50px;
    margin-left: 10px;
    
  }
  
  .navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .navbar-toggler {
    display: block; /* Mostrar el botón del menú en pantallas medianas */
    width: 40px; /* Ajusta el ancho del botón */
    height: 40px; /* Ajusta la altura del botón */
    padding: 5px; /* Ajusta el espacio interior del botón */
    border: none; /* Elimina el borde del botón */
    background-color: transparent; /* Fondo transparente */
    outline: none; /* Elimina el borde al hacer clic */
    margin-right: 20px; /* Ajusta el margen derecho del botón */
  }

  .navbar-toggler:hover,
  .navbar-toggler:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }

  .navbar-toggler-icon {
    width: 30px;
    height: 30px;
    background-color: #00000007;
    border-radius: 50%;
  }

  .navbar-links a {
    margin-right: 20px;
  }

  .logo-login {
    margin-left: 20px;
  }
}
@media screen and (min-width: 429px) and (max-width: 768px){
  .navbar {
    height: 75px;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 20px 0;
  }

  .navbar-toggler {
    width: 40px; /* Ajusta el ancho del botón */
    height: 40px; /* Ajusta la altura del botón */
    padding: 5px; /* Ajusta el espacio interior del botón */
    border: none; /* Elimina el borde del botón */
    background-color: transparent; /* Fondo transparente */
    outline: none; /* Elimina el borde al hacer clic */
    margin-right: 4px;
  
  }

  .navbar-toggler:hover,
  .navbar-toggler:focus { /* Elimina el efecto de hover y el borde de foco */
    background-color: transparent;
    border: none;
    outline: none;
  }

  .navbar-toggler-icon {
    width: 30px; /* Ajusta el ancho del ícono */
    height: 30px; /* Ajusta la altura del ícono */
    background-color: #00000007; /* Color del ícono */
    border-radius: 50%; /* Ajusta la forma del ícono */ 
  
  }

  .btn-primary {
    margin-right: 0;
    width: 100%;
    max-width: 150px;
    height: auto;
  }

  .logo-login {
    width: 30px;
    height: 20px;
    margin-left: 5px; /* Ajusta el margen izquierdo de las imágenes */
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
 
  .navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .navbar-toggler {
    display: block; /* Mostrar el botón del menú en pantallas medianas */
    width: 40px; /* Ajusta el ancho del botón */
    height: 40px; /* Ajusta la altura del botón */
    padding: 5px; /* Ajusta el espacio interior del botón */
    border: none; /* Elimina el borde del botón */
    background-color: transparent; /* Fondo transparente */
    outline: none; /* Elimina el borde al hacer clic */
    margin-right: 20px; /* Ajusta el margen derecho del botón */
  }

  .navbar-toggler:hover,
  .navbar-toggler:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }

  .navbar-toggler-icon {
    width: 30px;
    height: 30px;
    background-color: #00000007;
    border-radius: 50%;
  }

  .navbar-links a {
    margin-right: 20px;
  }

  .logo-login {
    margin-left: 20px;
  }
}

