body {
  font-family: 'Times New Roman', Times, serif;
  margin: 0; /* Elimina el margen predeterminado del body */
  padding: 0; /* Elimina el padding predeterminado del body */
}

.home-container {
  position: relative;
  height: 100vh; /* Cambiado de min-height a height */
  overflow: hidden; /* Cambiado a hidden para evitar el scroll */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../componentes/assets/imagenes/fondo.jpg');
  background-size: cover; /* Ajusta el tamaño del fondo para cubrir todo el contenedor */
  background-position: center;
  z-index: -1;
}

.carrusel-container {
  position: absolute;
  top: 85%; /* Mantiene la posición original */
  left: 15%; /* Mantiene la posición original */
  transform: translate(-50%, -50%);
  width: 35%;
}

.titulo {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 700;
  text-align: center; /* Centra el texto */
}

.logo-carousel {
  width: 35%; /* Cambiamos el ancho para que sea 100% y se ajuste al contenedor */
  height: auto; /* Permitimos que la altura se ajuste automáticamente para mantener la proporción de la imagen */
  display: block;
  margin: 0 auto;
}

@media (max-width: 427px) {
  
  .background-image {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 65%;
    background-image: url('../../componentes/assets/imagenes/fondo.jpg');
    background-size: cover; /* Ajusta el tamaño del fondo para cubrir todo el contenedor */
    
  }
  .titulo {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center; /* Centra el texto */
  }

  .carrusel-container {
    position: absolute;
    top: 68%; /* Mantiene la posición original */
    left: 50%; /* Mantiene la posición original */
    transform: translate(-50%, -50%);
    width: 45%;
  }

  .logo-carousel {
    width: 100%; /* Hacemos que las imágenes del carrusel ocupen el 100% del ancho en dispositivos móviles */
  }

  @media (min-width: 428px) and (max-width: 809px) {
    
    .carrusel-container {
      width: 70%; /* Ajusta el ancho del contenedor del carrusel según sea necesario */
      left: 50%;
      transform: translateX(50%);
      margin-top: 0%;
    }
  
    .logo-carousel {
      width: 100%; /* Ajusta el ancho de las imágenes del carrusel para que ocupen todo el ancho disponible */
      max-width: 200px; /* Limita el ancho máximo de las imágenes para evitar que se vean distorsionadas */
    }
  }
}
