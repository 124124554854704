/* Estilos para el cuerpo de la página */
body {
  font-family: 'Times New Roman', Times, serif;
}

/* Estilos para el elemento de video */
.video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  cursor: pointer;
}

/* Estilos para el contenedor de botones */
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  margin-top: 10%;
  margin-left: 2%;
}

/* Estilos para los botones */
.button {
  margin: 25px 10px;
  width: 10%;
  color: white;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.308);
  border: none;
  border-radius: 50px;
}

.button:hover {
  background-color: rgba(190, 190, 199, 0);
}

/* Estilos para el Offcanvas */
.custom-offcanvas {
  background-color: rgba(255, 255, 255, 0.5) !important;
  font-weight: 800;
}

.titulo-cuerpo {
  font-weight: 900 bold;
  color: rgb(8, 23, 139);
  align-items: center;
  text-align: center;
  height: 12%;
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px;
}

/* Estilos para el cuerpo del Offcanvas */
.custom-offcanvas-body {
  color: rgb(8, 23, 139);
  background-color: rgba(255, 255, 255, 0.027) !important;
  border: none;
  font-weight: 800;
  margin-top: 30%;
  text-align: justify;
  font-family: 'Times New Roman', Times, serif;
  font-size: 17px;
}

/* Media queries para diferentes tamaños de pantalla */
@media (max-width: 428px) {
  .button-container {
    top: 15%;
  }

  .button {
    margin-top: 2%;
    font-size: 12px;
    padding: 8px 16px;
    width: 23%;
  }

  .custom-offcanvas {
    max-width: 80% !important;
  }

  .custom-offcanvas-body {
    max-width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .button-container {
    top: 15%;
  }

  .button {
    font-size: 12px;
    padding: 8px 16px;
    width: 23%;
  }
}

@media (min-width: 961px) {
  .button-container {
    top: 7%;
  }
}
