body{
  font-family: 'Times New Roman', Times, serif;
}

.custom-footer {
    background-color: rgba(190, 190, 199, 0.767);
    color: white;
    position: fixed; /* Si deseas que el pie de página esté fijo en la parte inferior */
    bottom: .5%;
    width: 100%;
    box-shadow: 0 4px 2px -2px gray;
    border-radius: 15px; /* Agrega bordes redondeados */
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    padding: 1px;
  }
  
  .footer-text {
    opacity: 0.9;
    font-weight: 700;
    padding-right: 25px;
    margin-left: 10PX;
    margin-right: 25px;
    margin-bottom: 2px;
    color: rgb(21, 6, 136);
  }
  
  .link-white,
  .footer-text a {
    color: rgb(21, 6, 136);
    text-decoration: none;
    margin-bottom: 1px;
  }
  
  .link-white:hover,
  .footer-text a:hover {
    text-decoration: underline;
  }
  