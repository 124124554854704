.editar-form-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.editar-form-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.editar-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.search-button, .save-button {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(190, 190, 199, 0.705);
  color: rgb(21, 6, 136);
  font-size: 16px;
  cursor: pointer;
}

.search-button:hover, .save-button:hover {
  background-color: #9b9b9b;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}
