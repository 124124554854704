/* Main.css */

.main-container{
  display: flex;
  align-items: top;
  justify-content: center;
  height: 100vh;
  width: 100%; /* Ancho completo del viewport */
  max-width: 100%; /* Asegura que no exceda el ancho máximo del viewport */
  background: url('../../../componentes/assets/imagenes/fondo.jpg') no-repeat center center;
  background-size: cover;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  padding-top: 20px;
}

.datetime {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  
  color: rgb(62, 62, 224);
}

.date {
  font-size: 2rem;
  margin-bottom: 10px;
}

.time {
  font-size: 3rem;
  font-weight: bold;
}
