body {
  font-family: 'Times New Roman', Times, serif;
  margin: 0;
  overflow-x: hidden;
}

.contacto-container {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  overflow-x: hidden;
}

.background-container10 {
  background-image: url('../../componentes/assets/imagenes/fcontacto.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
}

.formulario {
  width: 200%;
  padding: 15px;
  color: rgb(8, 23, 139);
  background-color: rgba(190, 190, 199, 0.705);
  border-radius: 10px;
  border: 1px solid #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-left: 2%;
}

.mapa {
  width: 160%;
  height: 100%;
  padding: 10px;
  background-color: rgba(190, 190, 199, 0.8);
  border-radius: 10px;
  border: 1px solid #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-right: 25%;
}

.campo {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 2px;
  color: rgb(8, 23, 139);
  font-weight: 700;
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: calc(100% - 22px);
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #000000;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fffbfb;
  color: rgb(8, 23, 139);
  font-weight: 600;
}

button {
  width: calc(25% - 22px);
  background-color: rgba(190, 190, 199, 0.8);
  color: rgb(8, 23, 139);
  padding: 10px 20px;
  border: solid 1px black;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 2px;
}

button:hover {
  background-color: rgb(8, 23, 139);
  color: rgba(190, 190, 199, 0.8);
}

.mapa iframe {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 427px) {
  .contacto-container {
    
    flex-direction: column;
    align-items: center;
  }

  .formulario-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .formulario,
  .mapa {
    width: 90%;
    margin: 0 auto;
  }

  .mapa-container {
    width: 100%;
    margin-bottom: 10%;
  }
  .mapa iframe {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  } 
  button {
    width: calc(35% - 22px); 
  }
}

@media (min-width: 428px) and (max-width: 805px){
  .contacto-container {
    
    flex-direction: column;
    align-items: center;
  }

  .formulario-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .formulario,
  .mapa {
    width: 90%;
    margin: 0 auto;
  }

  .mapa-container {
    width: 100%;
    margin-bottom: 10%;
  }
  .mapa iframe {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  } 
  button {
    width: calc(35% - 22px); 
  }
}

@media (min-width: 806px) and (max-width: 950px){
  .contacto-container {
    
    flex-direction: column;
    align-items: center;
  }

  .formulario-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .formulario,
  .mapa {
    width: 90%;
    margin: 0 auto;
  }

  .mapa-container {
    width: 100%;
    margin-bottom: 10%;
  }
  .mapa iframe {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  } 
  button {
    width: calc(35% - 22px); 
  }
}
