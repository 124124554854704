/* Estilos CSS base */
body {
  font-family: 'Times New Roman', Times, serif;
}

.background-container8 {
  background-image: url('../../componentes/assets/imagenes/fservicio.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
}

.card-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2%;
  padding: 40px 20px;
}

.card-serv {
  width: calc(30% - 20px);
  margin: 30px;
  background-color: rgba(190, 190, 199, 0.774);
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(190, 190, 199, 0.705);
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.514);
  cursor: pointer;
  height: 240px;
  transition: transform 0.5s; /* Agrega transición para suavizar el efecto */
}

.imagen {
  padding-top: 15px;
  padding-left: 10px;
  width: 125px;
  height: 125px;
  display: block;
  margin-left: 5%;
}

.card-body1 {
  padding: 30px;
}

.card-body1 h2 {
  text-align: end;
  color: rgb(21, 6, 136);
  font-weight: 700;
  font-size: 22px;
}

.card-body1 p {
  margin-bottom: 0;
  font-size: 16px;
}

.front-face,
.back-face {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d; /* Permite la transformación 3D */
  backface-visibility: hidden; /* Oculta la cara posterior durante la animación */
}

.front-face {
  z-index: 2; /* Asegura que la cara frontal esté delante */
}

.back-face {
  transform: rotateY(180deg);
  background-color: rgba(190, 190, 199, 0.055);
  padding: 10px;
  text-align: justify;
  font-weight: 700;
  color: rgb(21, 6, 136);
  visibility: hidden;
  transition: transform 0.5s; /* Agrega una transición para suavizar el efecto */
}

.card-serv.flipped .front-face {
  visibility: hidden;
}

.card-serv.flipped .back-face {
  visibility: visible;
  transform: rotateY(0deg); /* Corrige la rotación de la cara posterior */
}

/* Muestra la cara posterior cuando la tarjeta está girada */
.card-serv.flipped .back-face {
  visibility: visible;
}

/* Estilos para pantallas de hasta 800px */
@media (max-width: 800px) {
  .card-serv {
    width: calc(75% - 20px); /* Ancho de cada tarjeta (50% de la anchura total del contenedor) */
  }
  .card-serv.flipped .back-face {
    visibility: visible;
    transform: rotateY(0deg); /* Corrige la rotación de la cara posterior */
    font-size: 14px;
  }
}

/* Estilos para pantallas de 800px a 1024px */
@media (min-width: 800px) and (max-width: 1024px) {
  .card-serv {
    width: calc(45% - 45px); /* Ancho de cada tarjeta (25% de la anchura total del contenedor) */
  }
  .card-serv.flipped .back-face {
    visibility: visible;
    transform: rotateY(0deg); /* Corrige la rotación de la cara posterior */
    font-size: 14px;
  }
}
