/* proyectos.css */

.body {
  margin: 0;
  overflow: hidden;
  font-family: 'Times New Roman', Times, serif;
}

.background-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /*background-color: rgb(183, 197, 219);*/
}

.background-container::before {
  content: "";
  background-image: url('../../componentes/assets/imagenes/fproyectos.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: -1;
}

.app {
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(190, 190, 199);
  width: 28rem;
  height: fit-content;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  opacity: 1;
  margin-top: 10%;
}

.card img {
  margin-top: -20%;
  width: 70%;
  border-radius: 20px;
}

.card .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
}

.card h2,
.card p {
  margin: 0;
  text-align: right;
}

.card .additional-text {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.centralizar {
  margin-top: 5%;
}

.titulos {
  text-align: center;
  margin-top: 5%;
  color: blue;
  font-weight: 800;
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 427px){
  .card {
    width: 70%;
    margin-top: 5%;
  }

  .card img {
    width: 100%;
    margin-top: 0;
  }

  .center-reel,
  .centralizar {
    margin-top: 40%;
    
  }
  
}

@media (min-width: 428px) and (max-width: 809px) {
  .card {
    width: 90%;
    margin-top: 5%;
  }

  .card img {
    width: 100%;
    margin-top: 0;
  }

  .center-reel,
  .centralizar {
    margin-top: 30%;

  }
}
