.background-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.background-container2 {
  background-image: url('../../componentes/assets/imagenes/fclientes.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
}

.clientes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
}

.cliente-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 2%;
}

.cliente-row:last-child {
  margin-top: 10%;
}

.cliente-logo {
  width: 180px;
  height: 90px;
  margin: 10px;
  border-radius: 7px;
}

.prueba1 {
  border-radius: 25px;
}

.centrarlo {
  margin-top: 2%;
  color: rgb(8, 23, 139);
  font-weight: 700;
}

/* Media queries para hacer el diseño responsive */
@media (max-width: 427px){
  .cliente-row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
    margin-top: 10%;
    margin-left: 10%;
  }

  .cliente-logo {
    width: calc(70% - 40px); /* Calcula el 50% del ancho del contenedor menos el margen */
    max-width: 150px; /* Ajusta el ancho máximo de las imágenes según tu preferencia */
    height: auto;
    margin: 10px; /* Ajusta el margen entre las imágenes */
  }
}

@media screen and (min-width: 428px) and (max-width: 809px) {
  .cliente-row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
    margin-top: 30%;
    margin-left: 10%;
  }

  .cliente-logo {
    width: calc(90% - 50px); /* Calcula el 50% del ancho del contenedor menos el margen */
    max-width: 150px; /* Ajusta el ancho máximo de las imágenes según tu preferencia */
    height: auto;
    margin: 10px; /* Ajusta el margen entre las imágenes */
  }
}



@media screen and (min-width: 810px) and (max-width: 1024px) {
  
  .cliente-row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
    margin-top: 30%;
  }
  .cliente-logo {
    width: 150px;
    height: 75px;
  }
}
