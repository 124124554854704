.crear-form-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.crear-form h2 {
  text-align: center;
  color: rgb(21, 6, 136);
  margin-bottom: 20px;
}

.crear-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: 600;
  color: rgb(21, 6, 136); /* Color del label */
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
  color: rgb(21, 6, 136); /* Color del texto dentro del input */
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  align-self: center;
  padding: 12px 24px;
  background-color: rgba(190, 190, 199, 0.705); /* Color de fondo del botón */
  color: rgb(21, 6, 136);
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #9b9b9b; /* Color de fondo al pasar el mouse */
}
