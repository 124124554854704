body{
  font-family: 'Times New Roman', Times, serif;
}


.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(190, 190, 199, 1);
  width: 24rem;
  height: fit-content;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  opacity: 1;
  margin-top: 10%;
}

.card img {
  margin-top: -20%;
  width: 100%;
  border-radius: 20px;
}

.card .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.card h2,
.card p {
  margin: 0;
  text-align: right;
  color: rgb(0, 18, 155);
  font-weight: 600;
  
}

.card .additional-text {
  font-size: 14px;
  
  margin-top: 10px;
}

/* Estilo para pantallas más pequeñas */
@media screen and (max-width: 780px) {
  /* ... (otros estilos) */
}